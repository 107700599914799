import dayjs from 'dayjs'
import styles from './_resources.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation, SwiperOptions } from 'swiper/core'
import { RefObject, useEffect, useState } from 'react'
import classNames from 'classnames'
import useHover from '../utils/hooks/useHover'
import Image from 'next/legacy/image'
import { ExternalResource } from '../__generated__/graphql'

SwiperCore.use([Autoplay, Navigation])

const Blogs: React.FC<{ latestBlogPosts: ExternalResource[] }> = ({
  latestBlogPosts
}) => {
  if (!latestBlogPosts) return <></>

  const [activeId, setActiveId] = useState(0)

  let interval
  useEffect(() => {
    interval = setInterval(() => setActiveId(activeId === 0 ? 1 : 0), 10000)
    return () => {
      clearInterval(interval)
    }
  }, [activeId])

  const formatDate = (date) => dayjs(date).format('MMM DD, YYYY')

  const tabs = [
    'all',
    'bass',
    'drums',
    'guitar',
    'keys',
    'leadership',
    'videos',
    'vocals',
    'worship'
  ]

  const slides = tabs.map((tab) => (
    <SwiperSlide key={tab}>
      <a
        href={
          'https://worshiponline.com/blog/?cat=' + (tab == tabs[0] ? '' : tab)
        }
        target="_blank"
        rel="noreferrer"
      >
        {tab}
      </a>
    </SwiperSlide>
  ))

  const Post: React.FC<{ post: ExternalResource; id: number }> = ({
    post,
    id
  }) => {
    const [ref, isHovered] = useHover()

    useEffect(() => {
      if (isHovered) {
        if (id !== activeId) setActiveId(id)
      }
    }, [isHovered])

    return (
      <a
        ref={ref as RefObject<HTMLAnchorElement>}
        className={classNames(styles.post, {
          [styles.isPostActive]: id === activeId
        })}
        href={post.url}
        target="_blank"
        rel="noreferrer"
      >
        <blockquote cite={post.url}>
          <div className={styles.concatenated}>{post.title}</div>
          <div className={styles.postInfo}>
            {post.author} {post.date && `| ${formatDate(post.date)}`}
          </div>
        </blockquote>
      </a>
    )
  }

  const swiperParams: SwiperOptions = {
    navigation: true,
    breakpoints: {
      620: {
        spaceBetween: 5
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      800: {
        spaceBetween: 10
      },
      850: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 0
      },
      950: {
        spaceBetween: 5
      },
      1000: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 0
      },
      1100: {
        spaceBetween: 5
      },
      1215: {
        slidesPerView: 6,
        slidesPerGroup: 4,
        spaceBetween: 0
      }
    }
  }

  return (
    <div className={`${styles.resource} is-flex-grow-1`}>
      <div className={`${styles.blogs}`}>
        <nav>
          <Swiper {...swiperParams}>{slides}</Swiper>
        </nav>
        <article className={styles.blogPosts}>
          <div className={styles.imageContainer}>
            <Image
              src={latestBlogPosts[activeId].previewUrl}
              layout="fill"
              objectFit="cover"
            />
            {latestBlogPosts.map((post, id) => {
              return <Post key={post.title} post={post} id={id} />
            })}
          </div>
        </article>
      </div>
    </div>
  )
}

export default Blogs
