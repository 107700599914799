import styles from './_topArtists.module.scss'
import ArtistComponent from './ArtistComponent'
import classNames from 'classnames'

const TopArtists: React.FC<any> = ({ artists: topArtists }) => {
  if (!topArtists) return <></>

  const artists = topArtists.map((artist) => {
    return (
      <div
        key={artist.id}
        className={classNames('column is-one-third is-half-mobile')}
      >
        <ArtistComponent artist={artist} />
      </div>
    )
  })

  return (
    <div
      className={`${styles.topArtists} columns is-variable is-multiline is-mobile is-3-fullhd`}
    >
      {artists}
    </div>
  )
}

export default TopArtists
