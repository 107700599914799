import { useContext, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useChat } from 'react-live-chat-loader'
import { t } from '../utils/i18n'
import Block from './../components/Block'
import RecentlyAdded from './../components/RecentlyAdded'
import TopArtists from './../components/TopArtists'
import Playlists from './../components/Playlists'
import Store from './../components/Store'
import Resources from './../components/Resources'
import Layout from '../components/Layout'
import styles from './_index.module.scss'
import { artistsPath, songsPath } from '../utils/routeHelpers'
import { FilterValues } from '../components/ui/ListSort'
import { useRouter } from 'next/router'
import useInView from 'react-cool-inview'
import { staticProps } from '../utils/createApolloClient'
import { ModalsContext } from '../utils/modals'
import { HOMEPAGE_DATA_QUERY } from '../gql/queries/homepage_data_query'
import { STORE_SONG_QUERY } from '../gql/queries/store_song_query'
import { TOP_ARTIST_QUERY } from '../gql/queries/top_artist_query'
import { useAuth } from '../utils/auth'

const FeaturedHomepageBlock = dynamic(
  () => import('./../components/FeaturedHomepageBlock')
)
const TrendingBlock = dynamic(() => import('./../components/Trending'))

const IndexPage = ({ storeSongsHomepageData, homepageData, topArtistData }) => {
  const router = useRouter()

  const removeOpenParam = () => {
    const { open, ...routerQuery } = router.query
    router.replace({
      query: { ...routerQuery }
    })
  }

  const RecentlyAddedBlock = Block(RecentlyAdded)
  const TopArtistsBlock = Block(TopArtists)
  const PlaylistsBlock = Block(Playlists)
  const StoreBlock = Block(Store)
  const ResourcesBlock = Block(Resources)
  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve()
  })

  const [, loadChat] = useChat()
  const { openModal, closeModal } = useContext(ModalsContext)
  const { isAuthenticated, isLoading } = useAuth()

  useEffect(() => {
    switch (router.query.open) {
      case 'contact':
        loadChat({ open: true })
        break
      case 'reset_password':
        openModal({
          name: 'CreateNewPassword',
          props: {
            hideModal: closeModal
          },
          modalTitle: 'Create New Password'
        })
        break
      case 'login':
        if (!isLoading) {
          if (!isAuthenticated)
            openModal({
              name: 'SignIn',
              props: {
                onClose: () => {
                  closeModal()
                  removeOpenParam()
                }
              },
              onModalClose: removeOpenParam,
              modalTitle: t('Sign In')
            })
          else removeOpenParam()
        }
        break
    }
  }, [router.query, isAuthenticated, isLoading])

  return (
    <Layout
      pageTitle={t('Worship Online | Resources & Tools for Your Worship Team')}
      pageDescription={t(
        'Full band tutorials and lessons for hundreds of worship songs. Download high quality chord charts/tabs in any key. Learn how to play like the album.'
      )}
    >
      <div className="container">
        <div className={styles.featuredHomepage}>
          <FeaturedHomepageBlock data={homepageData?.featuredHomepageBlocks} />
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="columns">
            <TrendingBlock songs={homepageData?.trendingSongs} />
          </div>

          <div className="columns is-variable is-7-fullhd is-flex-wrap">
            <RecentlyAddedBlock
              viewAllHref={songsPath({
                orderBy: FilterValues.NEWEST
              })}
              title={t('Recently Added')}
              songs={homepageData?.recentSongs}
              blockClasses={`${styles.recentlyAdded} is-12-tablet is-5-desktop is-flex-column`}
            />
            <TopArtistsBlock
              title={t('Top Artists')}
              viewAllHref={artistsPath({
                orderBy: 'CURRENTLY_TRENDING'
              })}
              artists={topArtistData?.topArtists}
              blockClasses={`${styles.topArtists} is-12-tablet is-7-desktop is-flex-column`}
            />
          </div>
        </div>
      </div>
      <div ref={observe}>
        {inView && (
          <>
            <div className="theme-color-wrapper section">
              <div className="container">
                <div className="columns">
                  <PlaylistsBlock
                    title={t('Playlists')}
                    viewAllHref="/playlists"
                  />
                </div>
              </div>
            </div>

            <div className="section">
              <div className="container">
                <div className="columns">
                  <StoreBlock
                    hasContainer={false}
                    title={t('Store')}
                    storeSongsHomepage={
                      storeSongsHomepageData?.storeSongsHomepage
                    }
                    viewAllHref="https://app.worshiponline.com/store"
                    viewAllText={t('Go to store')}
                  />
                </div>
              </div>
            </div>

            <div className="section">
              <div className="container">
                <div className="columns">
                  <ResourcesBlock
                    openInNewTab={true}
                    latestPodcast={homepageData?.latestPodcast}
                    latestBlogPosts={homepageData?.latestBlogPosts}
                    latestVideoPost={homepageData?.latestVideoPost}
                    title={t('resources')}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export const getStaticProps = staticProps({
  additionalProps: async ({ client }) => {
    const { data: storeSongsHomepageData } = await client.query({
      query: STORE_SONG_QUERY
    })
    const { data: homepageData } = await client.query({
      query: HOMEPAGE_DATA_QUERY
    })
    const { data: topArtistData } = await client.query({
      query: TOP_ARTIST_QUERY
    })

    return {
      storeSongsHomepageData,
      homepageData,
      topArtistData
    }
  }
})

export default IndexPage
