import styles from './_store.module.scss'
import { t } from '../utils/i18n'
import {
  storeSongPath,
  topKeysPath,
  topMultitracksPath
} from '../utils/routeHelpers'
import { joinedArtistsStr } from '../utils/songs/artists'
import Image from 'next/legacy/image'
import React from 'react'
import { Song } from '../__generated__/graphql'

interface Props {
  songs: Pick<Song, 'id' | 'title' | 'artists' | 'album'>[]
}

const TopKeyPatches: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props.songs.map((song, i) => (
        <div key={song.id} className={`column is-3-tablet is-half-mobile`}>
          <a
            href={storeSongPath(song) + '?tab=premium_patches'}
            className={`${styles.storeArtist}`}
          >
            <figure>
              <div className="is-square">
                <div>
                  <Image
                    src={song.album.cover.medium}
                    className="is-circle"
                    alt={song.title}
                    width={200}
                    height={200}
                    layout="responsive"
                    sizes="(max-width: 256px) 66px,
                    (max-width: 384px) 100px,
                    (max-width: 769px) 200px,
                    (max-width: 1024px) 180px,
                    (max-width: 1216px) 100px,
                    (max-width: 1408px) 180px,
                    205px"
                  />
                </div>
              </div>
              <figcaption>
                <h4>{song.title}</h4>
                <h5>{joinedArtistsStr(song.artists)}</h5>
              </figcaption>
            </figure>
          </a>
        </div>
      ))}
    </>
  )
}

const TopMultitrackStems: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props.songs.map((song, i) => (
        <div key={song.id} className={`column is-3-tablet is-half-mobile`}>
          <a
            href={storeSongPath(song) + '?tab=multi_tracks'}
            className={`${styles.storeArtist}`}
          >
            <figure>
              <div className="is-square">
                <div>
                  <Image
                    src={song.album.cover.medium}
                    className="is-circle"
                    alt={song.title}
                    layout="responsive"
                    width={200}
                    height={200}
                    sizes="(max-width: 256px) 66px,
                    (max-width: 384px) 100px,
                    (max-width: 769px) 200px,
                    (max-width: 1024px) 180px,
                    (max-width: 1216px) 100px,
                    (max-width: 1408px) 180px,
                    205px"
                  />
                </div>
              </div>
              <figcaption>
                <h4>{song.title}</h4>
                <h5>{joinedArtistsStr(song.artists)}</h5>
              </figcaption>
            </figure>
          </a>
        </div>
      ))}
    </>
  )
}

const Store: React.FC<any> = ({ storeSongsHomepage }) => {
  if (!storeSongsHomepage) return <></>

  return (
    <>
      <div className={styles.storeContainer}>
        <div className={styles.storeHeader}>
          <a href={topMultitracksPath()}>
            <h3>
              {t('Top Multitrack')} <strong>{t('Stems')}</strong>
            </h3>
          </a>
        </div>
        <div className={styles.storeWrapper}>
          <div className="columns is-mobile">
            <TopMultitrackStems songs={storeSongsHomepage.topMultitrackStems} />
          </div>
        </div>
      </div>
      <div className={styles.storeContainer}>
        <div className={styles.storeHeader}>
          <a href={topKeysPath()}>
            <h3>
              {t('Top Keys')} <strong>{t('Patches')}</strong>
            </h3>
          </a>
        </div>
        <div className={styles.storeWrapper}>
          <div className="columns is-mobile">
            <TopKeyPatches songs={storeSongsHomepage.topKeyPatches} />
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(Store)
