import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import Image from 'next/legacy/image'
import styles from './_resources.module.scss'
import { t } from '../utils/i18n'
import LatestBlogPosts from './LatestBlogPosts'
import classnames from 'classnames'
import { ExternalResource } from '../__generated__/graphql'

const LatestPodcast: React.FC<{ latestPodcast: ExternalResource }> = ({
  latestPodcast
}) => {
  if (!latestPodcast) return <></>

  const imgUrl = require('@public/images/wo_podcast_online.png')

  return (
    <a
      href={latestPodcast.url}
      target="_blank"
      rel="noreferrer"
      className={`${styles.resourceWrapper} ${styles.podcast}`}
    >
      <div className="columns is-mobile is-align-items-center">
        <div className="column is-6-mobile">
          <Image
            className={styles.podcastImage}
            src={imgUrl}
            alt={latestPodcast.subtitle}
            width={155}
            height={155}
          />
        </div>
        <div className="column is-6-mobile">
          <h4 className={styles.podcastTitle}>{latestPodcast.title}</h4>
          <p className={styles.podcastSubtitle}>{latestPodcast.subtitle}</p>
          <div className="has-text-right">
            <div className="button is-primary">{t('Listen Now')}</div>
          </div>
        </div>
      </div>
    </a>
  )
}

const LatestVideoPost: React.FC<{ latestVideoPost: ExternalResource }> = ({
  latestVideoPost
}) => {
  if (!latestVideoPost) return <></>
  return (
    <div className={`${styles.resourceWrapper} ${styles.videos}`}>
      <a href={latestVideoPost.url} target="_blank" rel="noreferrer">
        <div className="is-relative">
          <FontAwesomeIcon icon={faPlayCircle} />
          {latestVideoPost.previewUrl && (
            <Image
              className={styles.videoImg}
              src={latestVideoPost.previewUrl}
              alt={latestVideoPost.title}
              width={250}
              height={140}
              objectFit="contain"
            />
          )}
        </div>
        <h4 className={classnames(styles.podcastTitle, styles.concatenated)}>
          {latestVideoPost.title}
        </h4>
      </a>
    </div>
  )
}

const Resources: React.FC<any> = ({
  latestPodcast,
  latestBlogPosts,
  latestVideoPost
}) => {
  return (
    <div className="columns">
      <div className={`${styles.resource} column is-flex`}>
        <h3>
          <a href="https://worshiponline.com/podcast/">{t('Podcasts')} ▸</a>
        </h3>
        <LatestPodcast latestPodcast={latestPodcast} />
      </div>

      <div className={`${styles.resource} column is-flex`}>
        <h3>
          <a href="https://worshiponline.com/blog/">{t('Blogs')} ▸</a>
        </h3>
        <LatestBlogPosts latestBlogPosts={latestBlogPosts} />
      </div>

      <div className={`${styles.resource} column is-flex`}>
        <h3>
          <a href="https://worshiponline.com/blog/?cat=Videos">
            {t('Video Series')} ▸
          </a>
        </h3>
        <LatestVideoPost latestVideoPost={latestVideoPost} />
      </div>
    </div>
  )
}

export default Resources
