import styles from './_artistComponent.module.scss'
import classnames from 'classnames'
import { t } from '../utils/i18n'
import { artistPath } from '../utils/routeHelpers'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { Artist } from '../__generated__/graphql'

interface ArtistProps {
  artist: Artist
  mode?: string
  priority?: boolean
}

const ArtistComponent: React.FC<ArtistProps> = ({
  artist,
  mode = 'brief',
  priority = false
}) => {
  const isFullMode = mode === 'full'
  const isSquareMode = mode === 'brief'

  return (
    <Link
      href={artistPath(artist)}
      prefetch={false}
      className={classnames(styles.artistItem, {
        [styles.fullMode]: isFullMode
      })}
    >
      <div
        className={classnames(
          styles.artistHolder,
          { 'is-square': isSquareMode },
          { 'is-rectangle': isFullMode }
        )}
      >
        <div className={styles.artistSquareImage}>
          <Image
            priority={priority}
            src={artist.picture.medium}
            alt={artist.name}
            width={250}
            height={250}
          />
        </div>

        {isFullMode && (
          <>
            <div className={styles.artistName}>{artist.name}</div>

            <div className={styles.artistInfo}>
              <div className={styles.artistInfoRow}>
                <span>{artist.albumsCount}</span>
                <p>{t('Albums')}</p>
              </div>
              <div className={styles.artistInfoRow}>
                <span>{artist.songsCount}</span>
                <p>{t('Songs')}</p>
              </div>
            </div>
          </>
        )}
      </div>
      {isSquareMode && <div className={styles.artistName}>{artist.name}</div>}
    </Link>
  )
}

export default ArtistComponent
