import { t } from '../utils/i18n/index'
import styles from './_playlists.module.scss'
import Image from 'next/legacy/image'
import Link from 'next/link'

const playlists = ['popular', 'themes', 'genres', 'vibes']

const Playlists: React.FC = () => (
  <div className={styles.playlistContainer}>
    {playlists.map((playlist) => (
      <div className={styles.playlist} key={playlist}>
        <Link href={`/playlists#${playlist}`}>
          <span className={styles.playlistName}>{t(playlist)}</span>
          <Image
            src={require(`@public/images/${playlist}@2x.png`)}
            alt={playlist}
            width={350}
            height={250}
          />
        </Link>
      </div>
    ))}
  </div>
)

export default Playlists
