import React from 'react'
import SongComponent from './SongComponent'

const RecentlyAdded: React.FC<any> = ({ songs: recentSongs }) => {
  if (!recentSongs) return <></>

  const songs = recentSongs.map((song) => {
    return (
      <SongComponent
        key={song.id}
        song={song}
        isFlat={true}
        hasTextScroll={true}
        displayEditButton={true}
      />
    )
  })
  return <>{songs}</>
}

export default React.memo(RecentlyAdded)
