import { t } from '../utils/i18n'
import {
  Song,
  Artist,
  StoreSongsHomepage,
  ExternalResource
} from '../__generated__/graphql'

import styles from './_block.module.scss'

interface BlockProps {
  title: string
  openInNewTab?: boolean
  blockClasses?: string
  viewAllHref?: string
  viewAllText?: string
  hasContainer?: boolean
  songs?: Song[]
  artists?: Artist[]
  storeSongsHomepage?: StoreSongsHomepage
  latestPodcast?: ExternalResource
  latestVideoPost?: ExternalResource
  latestBlogPosts?: ExternalResource
}

function Block<T extends BlockProps>(Component: React.FC<T>) {
  return (passedProps: T) => {
    const props = { hasContainer: true, openInNewTab: false, ...passedProps }
    return (
      <section
        className={`${styles.block} ${
          props.blockClasses ? props.blockClasses : ''
        } column`}
      >
        {props.viewAllHref ? (
          <a
            href={props.viewAllHref}
            target={props.openInNewTab ? '_blank' : ''}
            rel={props.openInNewTab ? 'noreferrer' : ''}
          >
            <header className={styles.header}>
              <h2>{props.title}</h2>
              <div className={styles.moreLink}>
                {props.viewAllText ? props.viewAllText : t('View All')} ▸
              </div>
            </header>
          </a>
        ) : (
          <header className={styles.header}>
            <h2>{props.title}</h2>
          </header>
        )}
        {props.hasContainer ? (
          <div className={styles.blockContainer}>
            <Component {...props} />
          </div>
        ) : (
          <Component {...props} />
        )}
      </section>
    )
  }
}

export default Block
